
*{
    font-family: 'Courier New', Courier, monospace;
}
Link {
    cursor: pointer;
}

.PC {
    transform: rotate(10deg);
    fill: rgb(22, 22, 24);
    stroke: none;
    width: 20%;
    height: 100%;
}
.Controller {
    transform: rotate(-20deg);
    fill: rgb(22, 22, 24);
    stroke: none;
    width: 20%;
    height: 100%;
}
.Headphones {
    transform: rotate(5deg);
    fill: rgb(22, 22, 24);
    stroke: none;
    width: 20%;
    height: 100%;
}
.iconsContainer {
    display: flex;
    justify-content: space-around;
    position: sticky;
    position: -webkit-sticky;
    top: 10em;
    z-index: 1;
    right: 10%;
    height: 10vw;
    width: 100%;
}
.hrTitle {
    border: 2px solid rgb(22, 22, 24);
    color: rgb(22, 22, 24);
    background-color:rgb(22, 22, 24);
    width: 80%;
}

.mainHeaderMain {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    z-index: 2;
    color: rgb(22, 22, 24);
    background-color: white;
    box-shadow: 1em 1em  rgb(22, 22, 24);
    border-radius: 15rem 15rem 5em 5em;
    font-family: 'Courier New', Courier, monospace;
    
    
    min-height: fit-content;
    width: 28rem;
    text-align: center;
}

.displayPic {
    background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.116), rgba(92, 112, 156, 0.664)),
    url('../images/cute.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 25rem;
    min-height:25rem;

    transition: transform .2s;
    border: .5em solid rgb(22, 22, 24);
    
    vertical-align: bottom;
    border-radius: 50%;
    
}
@media ( max-width : 600px ) {
    .mainHeaderMain{
        width: 50vw;
        padding: 1rem;
    }
    .displayPic {
        min-width: 45vw;
        min-height: 45vw;

    }
    
}
.displayPic:hover {
    transform: scale(1.1);
    
}


.skillBox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 1em 0em 1em 0em;
    
}
@media (max-width: 600px) {
    .skillBox {
        flex-direction: column;
    }
}
.skillName {
    flex-basis: auto;
    
    
}
.skillGraphic {
    
    min-width: 80%;
    height: 3rem;
    text-align: right;
    border-radius: 1em;
    border:4px solid rgb(22, 22, 24);
    
}
/* IndividualGraphics */
.skillGraphicFill_JS {
    background-color: rgb(255, 255, 64);
    width: 80%;
    height: 100%;
    border-radius: 0.6em 0em 0em .6em;

}
.skillGraphicFill_Java {
    background-color: rgb(255, 59, 59);
    width: 80%;
    height: 100%;
    border-radius: 0.6em 0em 0em .6em;
}
.skillGraphicFill_Python {
    background-color: rgb(144, 158, 238);
    width: 90%;
    height: 100%;
    border-radius: 0.6em 0em 0em .6em;
}
.skillGraphicFill_C {
    background-color: rgb(255, 152, 93);
    width: 90%;
    height: 100%;
    border-radius: 0.6em 0em 0em .6em;
}
.skillGraphicFill_Unix {
    background-color: lightgreen;
    width: 80%;
    height: 100%;
    border-radius: 0.6em 0em 0em .6em;
}
/* Projects */
.projectsBlock {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.projectContainer {
    border: .5em solid rgb(22, 22, 24);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    width: 14em;
    height: 0;
    transition: .2s all ease-out;
    text-align: center;
    border-radius: 50%;
    background: linear-gradient(to bottom, transparent 0%, transparent 70%, rgb(36, 49, 65));
    background-size: 400% 400%;
    color:transparent;
    padding: 7em 0;
    z-index: 0;
    overflow: hidden;
    
}
.projectContainer:hover {
    transform: scale(1.1);
    background-position: 100% 100%;
    color:white;
    box-shadow: 0px 0px 20px rgb(0, 204, 255) ;
}
.projectContainer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    mix-blend-mode: multiply;
    z-index: -1;
}
.projectContainer h2{
    z-index: -2;
    text-decoration: none;
}
.buttonA {
    text-decoration: none;
    margin: 1em 1em;
    min-width: 14em;
}

