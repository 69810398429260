.App {
  text-align: center;
}
*:focus {
  outline: 0 !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #168ab2;
}

.mainBody {
  align-items: center;
  min-height: 100em;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  padding: 2em 0;
  color: white;
  z-index: 0;
}

.contentBlock { 
  margin: 2em;
  background-color: white;
  width: 75%;
  padding: 2em;
  border: .5em solid rgb(22, 22, 24);
  border-radius: 2em;
  color: rgb(22, 22, 24);
  box-shadow: 3em 3em  rgb(22, 22, 24);
  z-index: 2;
  overflow-x: hidden;
}
@media (max-width: 600px) {
  body {
      font-size: 80%;
  }
  .contentBlock {
      margin: 0.5;
      padding: 0.5;
      box-shadow: 1em 1em  rgb(22, 22, 24);
  }
}

.videoLinkButton{
  align-self: center;
  color: white;
  border: .3em solid rgb(22, 22, 24);
  border-radius: 1em;
  padding: 0.5em;
  height: fit-content;
  width: 40%;
  max-width: fit-content;
  margin: 0 auto;
  text-align: center;
  background-color: rgb(22, 22, 24);
  text-decoration: none;
}