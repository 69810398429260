.tag-button{
  display: flex;
  align-items: center;
  align-self: inherit;
  margin: 0.3rem 0;
  padding: 0.2rem 0.3rem;
  border:#161618 solid 2px;
  border-radius: 3px;
  font-size: 1.1rem;
}
.tag-list{
  position: absolute;
  background: white;
  border: 2px solid #161618;
  z-index: 2;
  text-align: left;
  padding: .5rem;
  border-radius: 5px;
  margin-bottom: 2rem;
}
.project-ul{
  padding: 0 ;
  margin: 0;
}

.project-li{
  list-style-type: none;
  padding: 0.4rem;
  margin: 0;
  cursor: pointer;
}

.project-li + .project-li {
  border-top: 1px solid #74a9bd;
}
.arrow-down {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 0.5rem 0.3rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
