.grid {
    margin: 4em auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
@media (max-width: 900px) {
    .grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (max-width: 600px) {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
}
.gridItem {
    overflow: hidden;
    height: 0;
    padding: 50% 0;
    position: relative;
    border-radius: 10%;
}
.gridItem:hover {
    transform: scale(110%);
}
.gridItem img {
    min-width: 100%;
    min-height: 100%;
    max-width: 150%;
    position: absolute;
    left: 0;
    top: 0;
}