.overlay {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 1s;
}
.overlay::before{
    opacity: 0;
}
.overlay::after{
    opacity: 1;
}
.overlay img {
    display:block;
    max-width: 80%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}