.appBar {
    height: 6em;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: flex-end;
    align-items: center;
    border: 0;
    border-bottom: .5em  solid rgb(22, 22, 24);
    color: rgb(22, 22, 24);
    z-index: 3;
    max-width: 100%;
}
.appBarButton {
    text-align: center;
    background-color: transparent;
    text-decoration: none;
    min-height: 50%;
    min-width:10vw;
    width:50px;
    padding: 0 2rem;
    border: 0em;
    margin: 0;
}
@media (max-width : 600px){
    .appBar {
        min-width:25vw;
        padding: 0;
        justify-content: space-around;
    }
}