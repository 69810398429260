
*{
    font-family: 'Courier New', Courier, monospace;
}
Link {
    cursor: pointer;
}
button {
    cursor: pointer;
}
.mainHeader {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
    
    color: rgb(22, 22, 24);
    background-color: white;
    box-shadow: 1em 1em  rgb(22, 22, 24);
    border-radius: 14em 15em 5em 5em;
    font-family: 'Courier New', Courier, monospace;
}
.hrTitle {
    border: 2px solid rgb(22, 22, 24);
    color: rgb(22, 22, 24);
    background-color:rgb(22, 22, 24);
    width: 80%;
}

.constellationsBody {
    align-items: center;
    min-height: 100em;
    display: flex;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    justify-content: baseline;
    padding: 2em;
    color: rgb(255, 255, 255);
}
.filmFinderBody {
    align-items: center;
    min-height: 100em;
    display: flex;
    flex-direction: column;
    background-color: rgb(40,44,51);
    justify-content: baseline;
    padding: 2em;
    color: rgb(255, 255, 255);
}
.comeFlyWithMeBody {
    align-items: center;
    min-height: 100em;
    display: flex;
    flex-direction: column;
    background-color: rgb(223,94,136);
    justify-content: baseline;
    padding: 2em;
    color: rgb(255, 255, 255);
}
.fallingBody {
    align-items: center;
    min-height: 100em;
    display: flex;
    flex-direction: column;
    background-color: rgb(6,11,23);
    justify-content: baseline;
    padding: 2em;
    color: rgb(255, 255, 255);
}
.sickDayBody {
    align-items: center;
    min-height: 100em;
    display: flex;
    flex-direction: column;
    background-color: rgb(183, 213, 123);
    justify-content: baseline;
    padding: 2em;
    color: rgb(255, 255, 255);
}
.constellationsBanner {
    background-image: url('../images/projects/constellation.png');
    width: 40vw;
    height: 40vw;
    max-width: 40em;
    max-height: 40em;
    background-size: cover;
}
.comeFlyWithMeBanner {
    background-image: url('../images/projects/comeflywithme.png');
    width: 90vw;
    height: 20vw;
    max-width: 50em;
    min-height: 25em;
    background-size: cover;
    background-position: center;
}
.filmFinderBanner {
    background-image: url('../images/projects/filmFinderPage/homepage.png');
    width: 80vw;
    height: 40vw;
    background-position: center;
    background-size: cover;
}
.modellingBanner {
    background-image: url('../images/projects/variousModelling/bedroom.png');
    width: 80vw;
    height: 40vw;
    background-position: center;
    background-size: cover;
}

.videoPlayer {
    width: 80vw;
    height: 30vw;
    justify-content: center;
    align-items: center;
    display: flex;
}

